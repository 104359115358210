import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import Students from './components/Students';
import ClassRegister from './components/ClassRegister';
import Professors from './components/Professors';
import Payments from './components/Payments';
import Lessons from './components/Lessons';
import Chart from './components/Chart';
import ProfessorsLessons from './components/ProfessorsLessons';

const reactAppContainer = document.getElementById('react-app');
const studentAppContainer = document.getElementById('student-app');
const registerAppContainer = document.getElementById('register-app');
const professorAppContainer = document.getElementById('professor-app');
const paymentsAppContainer = document.getElementById('payments-app');
const lessonsAppContainer = document.getElementById('lessons-app');
const chartAppContainer = document.getElementById('chart-app');
const professorsLessonsContainer = document.getElementById('professor-lessons-app');

if (reactAppContainer) {
  ReactDOM.render(<App />, reactAppContainer);
}

if (studentAppContainer) {
  ReactDOM.render(<Students />, studentAppContainer);
}

if (registerAppContainer) {
  ReactDOM.render(<ClassRegister />, registerAppContainer);
}

if (professorAppContainer) {
  ReactDOM.render(<Professors />, professorAppContainer);
}

if (paymentsAppContainer) {
  const data = paymentsAppContainer.dataset;
  ReactDOM.render(<Payments data={data} />, paymentsAppContainer);
}

if (lessonsAppContainer) {
  const data = lessonsAppContainer.dataset;
  ReactDOM.render(<Lessons data={data} />, lessonsAppContainer);
}

if (chartAppContainer) {
  const data = chartAppContainer.dataset;
  ReactDOM.render(<Chart data={data} />, chartAppContainer);
}

if (professorsLessonsContainer) {
  const data = professorsLessonsContainer.dataset;
  ReactDOM.render(<ProfessorsLessons data={data} />, professorsLessonsContainer);
}
