import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

function ClassRegister() {
  const [assigments, setAssigments] = useState([]);
  const [assigmentInfo, setInfo] = useState({});
  const [info, showInfo] = useState(false);
  const [registerClass, setRegister] = useState({
    StudentId: '',
    ProfessorId: '',
    date: '',
    duration: '',
    lesson_type: '',
    lecture: '',
    replacement: '',
  });
  const [professors, setProfessors] = useState([]);
  const [error, setError] = useState({ isError: false, message: '' });
  const [user, setUser] = useState([]);
  const assignationsPath = '/assignations/get';
  const professorsPath = '/professors/get';
  const lessonTypePath = '/administrator/lessontypes/get';

  function getProfessors() {
    fetch(professorsPath)
      .then((response) => response.json())
      .then((professorsInfo) => {
        setProfessors(professorsInfo);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getUser() {
    fetch(lessonTypePath)
      .then((response) => response.json())
      .then((user) => {
        setUser(user);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    fetch(assignationsPath)
      .then((response) => response.json())
      .then((data) => {
        setAssigments(data);
      })
      .then(getProfessors())
      .then(getUser())
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const columns = [
    { dataField: 'day', text: 'Día', headerStyle: { width: '16%', backgroundColor: '#F69B2F' } },
    { dataField: 'hour', text: 'Hora', headerStyle: { width: '12%', backgroundColor: '#F69B2F' } },
    { dataField: 'Professor.name', text: 'Profesor', headerStyle: { width: '22%', backgroundColor: '#F69B2F' } },
    { dataField: 'Student.name', text: 'Nombre alumno/a', headerStyle: { width: '25%', backgroundColor: '#F69B2F' } },
    { dataField: 'Student.mother', text: 'Apoderado', headerStyle: { width: '25%', backgroundColor: '#F69B2F' } },
  ];

  const rowEvents = {
    style: { cursor: 'pointer' },
    onClick: (event, row) => {
      showInfo(false);
      setInfo(row);
      showInfo(true);
      setRegister((prevState) => ({
        ...prevState,
        StudentId: row.Student.id,
        ProfessorId: row.Professor.id,
        duration: 1,
        lesson_type: row.lesson_type,
        replacement: 'No',
      }));
    },
  };

  function sendInfo() {
    fetch('./lessons/new', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(registerClass),
    })
      .then((response) => {
        if (response.status === 200) {
          alert('Clase registrada con éxito');
          setRegister({
            StudentId: '',
            ProfessorId: '',
            date: '',
            duration: '',
            lesson_type: '',
            lecture: '',
            replacement: '',
          });
          showInfo(false);
        }
      })
      .catch((error) => {
        console.log(error);
        alert('Hubo un problema al publicar, por favor, intenta denuevo');
      });
  }

  function onChange(event) {
    event.persist();
    setError((prevState) => ({
      ...prevState,
      isError: false,
      message: '',
    }));
    setRegister((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
    if (event.target.name === 'ProfessorId') {
      if (event.target.value == assigmentInfo.Professor.id) {
        setRegister((prevState) => ({
          ...prevState,
          replacement: 'No',
        }));
      } else {
        setRegister((prevState) => ({
          ...prevState,
          replacement: 'Si',
        }));
      }
    }
  }

  async function handleSubmit() {
    let emptyAttribute = false;
    if (Object.values(registerClass).indexOf('') > -1) {
      await setError((prevState) => ({
        ...prevState,
        isError: true,
        message: 'Debes rellenar todos los campos',
      }));
      emptyAttribute = true;
    }
    if (!error.isError) {
      if (!emptyAttribute) {
        sendInfo();
      }
    }
  }

  function registerDisplay(assigment) {
    return (
      <div>
        <h2>Registrar clase</h2>
        <hr className="line" />
        <h4>{`Alumno/a:  ${assigment.Student.name}`}</h4>
        <h5>{`Apoderado/a:  ${assigment.Student.mother}`}</h5>
        <form>
          <div className="control-group form-group">
            <h6>Seleccione el profesor</h6>
            <select id="ProfessorId" name="ProfessorId" className="browser-default custom-select" onChange={onChange}>
              <option value={assigment.ProfessorId}>{assigment.Professor.name}</option>
              {professors.map((professor) => (
                <option value={professor.id} key={professor.id}>{professor.name}</option>
              ))}
            </select>
          </div>
          <div className="control-group form-group">
            <input
              type="text"
              className="form-control"
              id="date"
              name="date"
              placeholder="Fecha (Ejemplo: Miercoles 21)"
              value={registerClass.date}
              onChange={onChange}
              required
            />
          </div>
          <div className="control-group form-group">
            <input
              type="text"
              className="form-control"
              id="lecture"
              name="lecture"
              placeholder="Materia"
              value={registerClass.lecture}
              onChange={onChange}
              maxLength="25"
              required
            />
            <p className="text-center">
              (
              {registerClass.lecture.length}
              /25)
            </p>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="control-group form-group">
                <label htmlFor="duration">Duración:</label>
                <br />
                <input type="radio" id="1,5" name="duration" value="1.5" onChange={onChange} />
                <label htmlFor="1,5">1,5 hr</label>
                <br />
                <input type="radio" id="1" name="duration" value="1" onChange={onChange} defaultChecked />
                <label htmlFor="1">1 hr</label>
                <br />
                <input type="radio" id="0,5" name="duration" value="0.5" onChange={onChange} />
                <label htmlFor="0,5">0,5 hr</label>
              </div>
            </div>
            <div className="col-md-6">
              <div className="control-group form-group">
                <label htmlFor="lesson_type">Material adicional</label>
                <br />
                {user.LessonAssigments.map((lessonAssigment) => (
                  <>
                    <input type="radio" id={lessonAssigment.LessonType.id} name="lesson_type" value={lessonAssigment.LessonType.id.toString()} onChange={onChange} checked={registerClass.lesson_type === lessonAssigment.LessonType.id.toString()} />
                    <label htmlFor={lessonAssigment.LessonType.id}>
                      {' '}
                      {lessonAssigment.LessonType.name}
                      {' '}
                      ($
                      {lessonAssigment.LessonType.charge}
                      )
                      {' '}
                    </label>
                    <br />
                  </>
                ))}

              </div>
            </div>
          </div>
          <div className="text-center">
            <input className="button" type="button" onClick={handleSubmit} value="Registrar" />
            <br />
            {error.isError
              ? (
                <p className="error">
                  Error:
                  {error.message}
                </p>
              )
              : <div />}
          </div>
        </form>
      </div>
    );
  }

  const sortingArray = ['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes'];

  const SortedAssigments = [].concat(assigments).sort((a, b) => sortingArray.indexOf(a.day) - sortingArray.indexOf(b.day));

  return (
    <div className="row">
      <div className="col-md-8 text-center">
        <h2>
          Asignaciones (
          {assigments.length}
          )
        </h2>
        <p>(Presionar una fila de la tabla para registrar)</p>
        <BootstrapTable
          keyField="name"
          data={SortedAssigments}
          columns={columns}
          pagination={paginationFactory()}
          rowEvents={rowEvents}
          hover
        />
      </div>
      <div className="col-md-4">
        {info
          ? registerDisplay(assigmentInfo)
          : <div />}
      </div>
    </div>
  );
}

export default hot(module)(ClassRegister);
