import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']

function Students() {
  const [students, setStudents] = useState([]);
  const [studentInfo, setInfo] = useState({});
  const [studentLessons, setStudentLessons] = useState([]);
  const [info, showInfo] = useState(false);
  const [inactiveMonths, setInactiveMonths] = useState([]);
  const studentsPath = '/students/get';
  const studentPath = '/students/lessons/';

  useEffect(() => {
    fetch(studentsPath)
      .then((response) => response.json())
      .then((data) => {
        setStudents(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  function getStudentInfo(id) {
    fetch(`${studentPath}` + `${id}`)
      .then((response) => response.json())
      .then((data) => {
        data.sort((a,b) => ( months.indexOf(a.month) >= months.indexOf(b.month) ? 1 : -1 ));
        setStudentLessons(data);
        
        let meses = [...months]
        for (const lesson of data) {
          if ((meses.includes(lesson.month))) {
            const index = meses.indexOf(lesson.month)
            if (index > -1) {
              meses.splice(index, 1);
            }
          }
        }
        setInactiveMonths(meses);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const columns = [
    { dataField: 'name', text: 'Nombre alumno/a', headerStyle: { backgroundColor: '#F69B2F' } },
    { dataField: 'mother', text: 'Apoderado', headerStyle: { backgroundColor: '#F69B2F' } },
  ];

  const rowEvents = {
    style: { cursor: 'pointer' },
    onClick: (event, row) => {
      setInfo(row);
      getStudentInfo(row.id);
      showInfo(true);
    },
  };

  const sortedStudents = [].concat(students).sort((a, b) => (a.name > b.name ? 1 : -1));

  function studentDisplay(student) {
    return (
      <div>
        <div className="row justify-content-center">
          <div className="col-md-12 text-center">
            <h3><strong>{student.name}</strong></h3>
            <hr className="line" />
            <h4>{`Apoderado: ${student.mother} - Número: ${student.number}` }</h4>
            <h5>{`${student.school} - ${student.generation}` }</h5>
            <h5>{`${student.address} - ${student.county}` }</h5>
            <hr className="line" />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-12 text-center">
            <h4>
              {`Clases realizadas:   
              ${studentLessons.length}`}
            </h4>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <ul>
              {months.map((month) => (
                <div> 
                  <h5> {month} </h5>
                  {
                    inactiveMonths.map((inactive) => (
                      (inactive === month) ? <div> Sin Clases </ div> : <div />
                    ))
                      
                  }
                  {studentLessons.map((lesson) => (
                    (lesson.month === month) ?
                    <div>
                      <li>{`Fecha: ${lesson.date} - Duración: ${lesson.duration} - Materia: ${lesson.lecture}`}</li>
                    </div>
                    : <div />
                  ))}
                </div>
              ))}
            </ul>
          </div>
          {`Total horas realizadas:  ${studentLessons.map((lesson) => lesson.duration).reduce((prev, next) => prev + next, 0)}`}
        </div>
        <br />
        <br />
        <div className="row justify-content-center">
          <div className="col-md-6 text-center">
            <a type="button" className="button" href={`/students/${student.id}`}>Editar</a>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="row">
      <div className="col-md-5">
        <h1>
          Alumnos (
          {sortedStudents.length}
          )
        </h1>
        <br />
        <BootstrapTable
          keyField="id"
          data={sortedStudents}
          columns={columns}
          pagination={paginationFactory()}
          rowEvents={rowEvents}
        />
      </div>
      <div className="col-md-1" />
      <div className="col-md-6">
        {info
          ? studentDisplay(studentInfo)
          : <div />}
      </div>
    </div>
  );
}

export default hot(module)(Students);
