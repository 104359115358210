import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']


function Professors() {
  const [professors, setprofessors] = useState([]);
  const [professorInfo, setInfo] = useState({});
  const [professorLessons, setprofessorLessons] = useState([]);
  const [info, showInfo] = useState(false);
  const [inactiveMonths, setInactiveMonths] = useState([]);
  const professorsPath = '/professors/get';
  const professorPath = '/professors/lessons/';

  useEffect(() => {
    fetch(professorsPath)
      .then((response) => response.json())
      .then((data) => {
        setprofessors(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  function getprofessorInfo(id) {
    fetch(`${professorPath}` + `${id}`)
      .then((response) => response.json())
      .then((data) => {
        data.sort((a,b) => ( months.indexOf(a.month) >= months.indexOf(b.month) ? 1 : -1 ));
        setprofessorLessons(data);

        let meses = [...months]
        for (const lesson of data) {
          if ((meses.includes(lesson.month))) {
            const index = meses.indexOf(lesson.month)
            if (index > -1) {
              meses.splice(index, 1);
            }
          }
        }
        setInactiveMonths(meses);


      })
      .catch((error) => {
        console.log(error);
      });
  }

  const columns = [
    { dataField: 'name', text: 'Nombre profesor/a', headerStyle: { backgroundColor: '#F69B2F' } },
  ];

  const rowEvents = {
    style: { cursor: 'pointer' },
    onClick: (event, row) => {
      setInfo(row);
      getprofessorInfo(row.id);
      showInfo(true);
    },
  };

  const sortedProfessors = [].concat(professors).sort((a, b) => (a.name > b.name ? 1 : -1));

  function professorDisplay(professor) {
    return (
      <div>
        <div className="row justify-content-center">
          <div className="col-md-12 text-center">
            <h3><strong>{professor.name}</strong></h3>
            <hr className="line" />
            <h4>{`Número: ${professor.number}` }</h4>
            <h5>{`Carrera: ${professor.carreer}` }</h5>
            <h5>{`${professor.address} - ${professor.county}` }</h5>
            <a href={professor.link}>
              {professor.link}
            </a>
            <hr className="line" />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-12 text-center">
            <h4>
              {`Clases realizadas:   
              ${professorLessons.length}`}
            </h4>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <ul>
              {months.map((month) => (
                <div> 
                  <h5> {month} </h5>
                  {
                    inactiveMonths.map((inactive) => (
                      (inactive === month) ? <div> Sin Clases </ div> : <div />
                    ))
                      
                  }
                  {professorLessons.map((lesson) => (
                    (lesson.month === month) ?
                    <div>
                      <li id="lesson">{`Fecha: ${lesson.date} - Duración: ${lesson.duration} - Materia: ${lesson.lecture}`}</li>
                    </div>
                    : <div />
                  ))
                  }
                </div>
              ))}
            </ul>
          </div>
          {`Total horas realizadas:  ${professorLessons.map((lesson) => lesson.duration).reduce((prev, next) => prev + next, 0)}`}
        </div>
        <br />
        <br />
        <div className="row justify-content-center">
          <div className="col-md-6 text-center">
            <a type="button" className="button" href={`/professors/${professor.id}`}>Editar</a>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="row">
      <div className="col-md-5">
        <h1>
          Profesores (
          {sortedProfessors.length}
          )
        </h1>
        <br />
        <BootstrapTable
          keyField="id"
          data={sortedProfessors}
          columns={columns}
          pagination={paginationFactory()}
          rowEvents={rowEvents}
        />
      </div>
      <div className="col-md-1" />
      <div className="col-md-6">
        {info
          ? professorDisplay(professorInfo)
          : <div />}
      </div>
    </div>
  );
}

export default hot(module)(Professors);
