/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { Line } from 'react-chartjs-2';

function Chart(props) {
  const { data: { id } } = props;
  const { data: { label } } = props;
  const { data: { tittle } } = props;
  const [numbers, setNumbers] = useState([]);
  const dataPath = `/userInfo/lessons/${id}`;

  useEffect(() => {
    fetch(dataPath)
      .then((response) => response.json())
      .then((data) => {
        setNumbers(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const data = {
    labels: ['Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre',
      'Octubre', 'Noviembre', 'Diciembre'],
    datasets: [
      {
        label,
        data: numbers,
        fill: false,
        backgroundColor: 'rgb(247, 147, 30)',
        borderColor: 'rgb(247, 147, 30)',
        tension: 0.1,
      },
    ],
  };

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  return (
    <div>
      <h1 className="title">{tittle}</h1>
      <Line data={data} options={options} />
    </div>
  );
}

export default hot(module)(Chart);
