import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';

function Lessons(props) {
  const { data: { month } } = props;
  const [month2, setMonth] = useState(month);
  const [lessons, setLessons] = useState([]);
  const [lessonsTypes, setTypes] = useState([]);
  const lessonsPath = `/lessons/get?month=${month}`;
  const typesPath = '/lessons/types/get';

  function getLessons() {
    fetch(lessonsPath)
      .then((response) => response.json())
      .then((data) => {
        setLessons(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(async () => {
    fetch(typesPath)
      .then((response) => response.json())
      .then((data) => {
        setTypes(data);
      })
      .then(getLessons())
      .catch((error) => {
        console.log(error);
      });
  }, []);

  function typeFormatter(cell, row) {
    let h = '';
    lessonsTypes.forEach((type) => {
      if (parseInt(row.lesson_type, 0) === type.id) {
        h = <p>{type.abbr}</p>;
      }
    });
    return h;
  }

  const columns = [
    {
      dataField: 'Student.name', text: 'Alumno/a', headerStyle: { width: '14%', backgroundColor: '#F69B2F' }, filter: textFilter({ placeholder: 'Buscar' }),
    },
    {
      dataField: 'Student.mother', text: 'Apoderado/a', sort: true, headerStyle: { width: '15%', backgroundColor: '#F69B2F' }, filter: textFilter({ placeholder: 'Buscar' }),
    },
    {
      dataField: 'Professor.name', text: 'Profesor/a', sort: true, headerStyle: { width: '12%', backgroundColor: '#F69B2F' }, filter: textFilter({ placeholder: 'Buscar' }),
    },
    {
      dataField: 'date', text: 'Fecha', headerStyle: { width: '11%', backgroundColor: '#F69B2F' }, filter: textFilter({ placeholder: 'Buscar' }),
    },
    { dataField: 'month', text: 'Mes', headerStyle: { width: '6%', backgroundColor: '#F69B2F' } },
    { dataField: 'lecture', text: 'Materia', headerStyle: { width: '16%', backgroundColor: '#F69B2F' } },
    { dataField: 'duration', text: 'Duración', headerStyle: { width: '8%', backgroundColor: '#F69B2F' } },
    { dataField: 'replacement', text: 'Reemplazo', headerStyle: { width: '10%', backgroundColor: '#F69B2F' } },
    {
      dataField: 'lesson_type', text: 'Tipo', formatter: typeFormatter, headerStyle: { width: '6%', backgroundColor: '#F69B2F' },
    },
  ];

  const rowEvents = {
    style: { cursor: 'pointer' },
    onClick: (event, row) => {
      window.location.href = `/lessons/${row.id}`;
    },
  };

  function handleMonthChange(event) {
    event.persist();
    window.location.href = `/lessons?month=${event.target.value}`;
  }

  return (
    <div className="row">
      <div className="col-12 text-center">
        <br />
        <h1>
          Clases registradas (
          {lessons.length}
          )
        </h1>
        <div className="row justify-content-center">
          <div className="col-3 text-center">
            <h5>{month2}</h5>
            <select className="form-select form-select-sm" name="month" id="month" onChange={handleMonthChange}>
              <option value="">Seleccione el mes</option>
              <option value="Marzo">Marzo</option>
              <option value="Abril">Abril</option>
              <option value="Mayo">Mayo</option>
              <option value="Junio">Junio</option>
              <option value="Julio">Julio</option>
              <option value="Agosto">Agosto</option>
              <option value="Septiembre">Septiembre</option>
              <option value="Octubre">Octubre</option>
              <option value="Noviembre">Noviembre</option>
              <option value="Diciembre">Diciembre</option>
            </select>
          </div>
        </div>
        <br />
        <BootstrapTable
          keyField="id"
          data={lessons}
          columns={columns}
          pagination={paginationFactory()}
          filter={filterFactory()}
          rowEvents={rowEvents}
          hover
        />
      </div>
    </div>
  );
}

export default hot(module)(Lessons);
