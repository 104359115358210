import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

function ProfessorsLessons(props) {
  const { data: { month } } = props;
  const { data: { hash } } = props;
  const [month2, setMonth] = useState(month);
  const infoPath = `/professors/month-lessons/info/${hash}?month=${month2}`;
  const [professor, setProfessor] = useState({});
  const [lessons, setLessons] = useState([]);
  const [lessonsTypesDict, setLessonsTypesDict] = useState({});

  function handleMonthChange(event) {
    event.persist();
    window.location.href = `/professors/month-lessons/${hash}?month=${event.target.value}`;
  }

  useEffect(() => {
    fetch(infoPath)
      .then((response) => response.json())
      .then((data) => {
        setLessonsTypesDict(data.lessonsTypesDict);
        setProfessor(data.professor);
        setLessons(data.lessons);
      })
      .catch((error) => console.log(error));
  }, []);

  function lessonTypeFormatter(cell, row) {
    return <p>{lessonsTypesDict[parseInt(row.lesson_type, 10)]}</p>;
  }

  const columns = [
    { dataField: 'date', text: 'Fecha', headerStyle: { width: '12%', backgroundColor: '#F69B2F' } },
    { dataField: 'Student.name', text: 'Alumno', headerStyle: { width: '16%', backgroundColor: '#F69B2F' } },
    { dataField: 'duration', text: 'Hrs', headerStyle: { width: '7%', backgroundColor: '#F69B2F' } },
    { dataField: 'lesson_type', text: 'Tipo', formatter: lessonTypeFormatter, headerStyle: { width: '10%', backgroundColor: '#F69B2F' } },
    { dataField: 'lecture', text: 'Materia', headerStyle: { width: '16%', backgroundColor: '#F69B2F' } },
  ];

  return (
    <div>
      <div className="row justify-content-center">
        <div className="col-md-5 text-center">
          <br />
          <h1>{professor.name}</h1>
          <hr className="line" />
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="row justify-content-center">
            <div className="col-md-6 text-center">
              <p>{month2}</p>
              <select className="form-select form-select-sm" name="month" id="month" onChange={handleMonthChange}>
                <option value="">Seleccione el mes</option>
                <option value="Marzo">Marzo</option>
                <option value="Abril">Abril</option>
                <option value="Mayo">Mayo</option>
                <option value="Junio">Junio</option>
                <option value="Julio">Julio</option>
                <option value="Agosto">Agosto</option>
                <option value="Septiembre">Septiembre</option>
                <option value="Octubre">Octubre</option>
                <option value="Noviembre">Noviembre</option>
                <option value="Diciembre">Diciembre</option>
              </select>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-12">
              <br />
              <h3 className="text-center">
                Clases realizadas:
                {' '}
                {lessons.length}
              </h3>
              <p className="text-center">
                Horas totales:
                {' '}
                {lessons.map((lesson) => lesson.duration).reduce((prev, next) => prev + next, 0)}
              </p>
              <BootstrapTable
                keyField="id"
                data={lessons}
                columns={columns}
                pagination={paginationFactory()}
              />
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="row justify-content-center" />
    </div>
  );
}

export default hot(module)(ProfessorsLessons);
